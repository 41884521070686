<template>
  <div>
    <b-card v-if="evalIsReady">
      <b-row class=" mb-1">
        <b-col md="12">
          <h4 class="text-center">
            Questionnaire d'évaluation des compétences
          </h4>
        </b-col>
      </b-row>
      <b-row class=" mb-1">
        <b-col sm="12">
          <h4>
            <feather-icon icon="AlertCircleIcon" />
            Informations générales
          </h4>
        </b-col>

        <b-col md="12">
          <span class="font-weight-bolder">Candidat : </span><span>{{ eval.evaluation.candidat }}</span>
        </b-col>
        <b-col md="12">
          <span class="font-weight-bolder">Société: </span><span>{{ eval.evaluation.societe }}</span>
        </b-col>
        <b-col md="12">
          <span class="font-weight-bolder">Agence: </span><span>{{ eval.evaluation.agence || '/' }}</span>
        </b-col>
        <b-col md="12">
          <span class="font-weight-bolder">Fonction : </span><span>{{ eval.evaluation.fonction.libelle_fonction }}</span>
        </b-col>
        <b-col md="12">
          <span class="font-weight-bolder">Compétence: </span><span>{{ eval.evaluation.competence.libelle_competence }}</span>
        </b-col>
      </b-row>
    </b-card>
    <b-card
      v-if="!evalIsReady"
      title="Nouvelle évaluation"
    >
      <b-row cols="1">
        <b-col md="12">
          <b-card-text>
            <p>
              Un questionnaire de <strong>20 à 30 questions à choix multiples</strong> va vous être proposé dans le domaine de compétence que vous sélectionnerez. En contrôles de compactage, vous devrez choisir entre la méthode énergie variable ou la méthode à énergie constante. En contrôles d'inspection visuelle et télévisuelle, vous devez choisir entre réseau neuf ou réseau en service. Et en essais d’étanchéité, vous devrez choisir entre les essais à l’air, les essais à l’eau ou les essais haute-pression. <strong>Une ou plusieurs réponses peuvent être correctes.</strong>
            </p>
            <p>
              Chaque question est notée sur 1 point. Si vous omettez une ou plusieurs des réponses correctes vous n’aurez pas la totalité du point.
            </p>
            <p>
              Vous devez vous munir de <strong>papiers brouillons, stylo et calculatrice scientifique</strong>.
            </p>
            <p>
              Tous les documents internes et référentiels techniques sont autorisés et même vivement conseillés.
            </p>
            <p>
              A la fin du questionnaire, <strong>une note sur 20</strong> sera calculée et une <strong>suggestion de qualification</strong> sera proposée. Pour être validé, cet avis devra être visé en interne par une personne compétente. Vous pourrez exporter votre note sous format PDF.
            </p>
            <p>
              Avant de commencer, veillez complétez les champs suivants :
            </p>
          </b-card-text>
        </b-col>
      </b-row>

      <validation-observer ref="infoEvaluation">
        <b-row
          cols="1"
          class="mt-1"
        >
          <b-col md="6">
            <b-form-group label="Fonction">
              <b-form-select
                v-model="evaluation.fonctionId"
                :options="getFonctions"
                :state="(evaluation.fonctionId === null) ? false : true"
              />
            </b-form-group>
          </b-col>

          <b-col
            md="6"
          >
            <b-form-group label="Compétence">
              <b-form-select
                v-model="evaluation.competenceId"
                :options="getCompetences"
                :state="(evaluation.competenceId === null ) ? false : true"
                @change="setDocumentation"
              />
            </b-form-group>
          </b-col>

          <b-col
            md="6"
          >
            <b-form-group label="Nom">
              <validation-provider
                #default="{ errors }"
                name="Nom"
                rules="required"
              >
                <b-form-input
                  id="nom"
                  v-model="evaluation.nom"
                  name="nom"
                  type="text"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col
            md="6"
          >
            <b-form-group label="Prénom">
              <validation-provider
                #default="{ errors }"
                name="Prénom"
                rules="required"
              >
                <b-form-input
                  id="prenom"
                  v-model="evaluation.prenom"
                  name="prenom"
                  type="text"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </validation-observer>
      <b-row>
        <b-col md="12">
          <p v-if="documentation != null">
            Il est préférable de vous munir des documents suivants : <strong class="text-danger">{{ documentation }}</strong>
          </p>

          <b-button
            variant="primary"
            @click="confirmForm()"
          >
            Démarrer
          </b-button>
        </b-col>
      </b-row>
    </b-card>
    <b-card v-if="evalIsReady && !correction">
      <div>
        <div
          v-for="(question, index) in eval.questions"
          :key="index"
        >
          <b-row class="mt-1">
            <b-col
              sm="12"
              class="mt-1 font-weight-bold"
            >
              <span class="mr-2">Q{{ index+1 }}</span>
              <span>{{ question.categorie.libelle_categorie }} - {{ question.libelle }}</span>
            </b-col>
          </b-row>

          <b-row >
            <b-col
              v-if="question.media_question"
              md="3"
              class="m-auto"
            >
              <img
                class="w-100"
                :src="question.media_question"
                alt=""
              >
            </b-col>
            <b-col
              v-if="question.video_question"
              md="4"
              class="m-auto"
            >
              <b-embed
                type="iframe"
                aspect="16by9"
                :src="question.video_question"
                allowfullscreen
              />
            </b-col>
          </b-row>
          <div class="mb-2">
            <b-row>
              <b-col
                v-for="(reponse, indexReponse) in question.reponseQuestion"
                :key="indexReponse"
                md="3"
              >
                <b-row>
                  <b-col
                    md="6"
                    class="m-auto"
                  >
                    <img
                      class="w-100"
                      :src="reponse.reponse.lien_media"
                      alt=""
                    >
                  </b-col>
                </b-row>
                <b-row cols-lg="0">
                  <b-col
                    md="2"
                    align-self="center"
                  >
                    <b-form-checkbox
                      name="is-vertical-menu-collapsed"
                      class="mr-0"
                      @change="saveResponseAction($event, reponse)"
                    />
                  </b-col>
                  <b-col md="10">
                    <span class="font-weight-bold">{{ reponse.reponse.libelle }}</span>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </div>
          <hr class="mb-2 mt-2">
        </div>
      </div>
      <b-button @click="getCorrection()">
        Valider
      </b-button>
    </b-card>
    <b-card
      v-if="correction"
    >
      <score-evaluation
        v-if="resultatEvaluation !== null"
        :series="resultatEvaluation.series"
        :note="resultatEvaluation.totalEvaluation"
        :reponsesok="resultatEvaluation.totalReponsesErreurEvaluation"
        :reponsesko="resultatEvaluation.totalReponsesCorrectEvaluation"
        :questions="resultatEvaluation.nbQuestions"
        :reponsesattendu="resultatEvaluation.totalReponsesCorrectAttenduEvaluation"
      />

      <b-row>
        <b-col
          offset-md="8"
          class="text-right"
        >
          <b-button
            class="btn-sm mr-1"
            variant="primary"
            :disabled="waitDownload(eval.evaluation.id)"
            @click="generatePDF(eval.evaluation.id)"
          >
            <b-spinner
              v-if="waitDownload(eval.evaluation.id)"
              :key="eval.evaluation.id"
              small
            />
            <span v-else>
              <feather-icon
                icon="FileTextIcon"
              />
              Télécharger le rapport
            </span>
          </b-button>
        </b-col>

        <b-col sm="12">
          <h3>Analyse détaillée de votre performance</h3>
          <p>
            Une analyse détaillée comprenant la correction du questionnaire, des commentaires sur les erreurs et des propositions d'actions d'amélioration, peut être réalisée en cliquant sur l'icône ci-dessous.
            Cette analyse sera réalisée par un expert en qualité et en contrôle des réseaux d'assainissement au sein de SQE Services et un rapport personnalisé vous sera retourné par mail.
            L'analyse détaillée a un coût de 80€ HT.>La mise en oeuvre des actions d'amélioration proposées pourra faire l'objet d'un devis séparé sur demande.
          </p>
        </b-col>

        <b-col
          offset-md="8"
          class="text-right"
        >
          <b-button
            class="btn-primary"
            @click="envoyerDemande(eval.evaluation.id)"
          >
            <b-spinner
              v-if="envoiDemande"
              small
            />
            <span v-else>
              <feather-icon icon="SendIcon" />
              Envoyer la demande
            </span>
          </b-button>
        </b-col>

      </b-row>
    </b-card>
  </div>

</template>

<script>
import {
  BCard,
  BCardText,
  BCol,
  BRow,
  BForm,
  BFormSelect,
  BFormInput,
  BFormGroup,
  BButton,
  BFormCheckbox,
  BEmbed,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'

import ScoreEvaluation from './score.vue'

export default {
  components: {
    BCard,
    BCardText,
    BCol,
    BRow,
    BFormSelect,
    BFormInput,
    BFormGroup,
    BButton,
    BFormCheckbox,
    BEmbed,
    ValidationProvider,
    ValidationObserver,
    ScoreEvaluation,
  },
  data() {
    return {
      required,
      startForm: false,
      correction: null,
      eval: null,
      evalIsReady: false,
      evaluationData: {
        responses: [],
      },
      documentation: null,
      fonctions: [],
      competences: [],
      evaluation: {
        fonctionId: null,
        competenceId: null,
        nom: null,
        prenom: null,
        societe: null,
        userId: null,
        date: null,
        candidat: null,
        etat: 'en_cours',
      },
      resultatEvaluation: null,
      isDownloading: [],
      envoiDemande: false,
    }
  },
  computed: {
    getCurrentUser() {
      return this.$store.getters['auth/currentUser']
    },
    getFonctions() {
      return this.fonctions?.map(elem => ({
        value: elem.id,
        text: elem.libelle_fonction,
      }))
    },
    getCompetences() {
      return this.competences?.map(elem => ({
        value: elem.id,
        text: elem.libelle_competence,
      }))
    },
    isTempsEcoule() {
      return this.$store.getters['timer/getIsTempsEcoule']
    },
  },
  watch: {
    isTempsEcoule(value) {
      if (value === true) {
        this.stopEvaluation()
      }
    },
  },
  beforeMount() {
    if (this.getCurrentUser.solde > 0) {
      this.$store.dispatch('fonctionCompetence/getAllCompetences', { actif: 'Actif' }).then(
        data => {
          this.competences = data
        },
      )
      this.$store.dispatch('fonctionCompetence/getAllFonctions', { actif: 'Actif' }).then(
        data => {
          this.fonctions = data
        },
      )
    } else {
      this.$swal({
        html: 'Credit épuisé, merci de vous rapprocher de SQE Services',
        title: 'Information',
        icon: 'warning',
        allowOutsideClick: false,
        allowEscapeKey: false,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
        .then(result => {
          if (result.isConfirmed) {
            this.$router.push({ name: 'mes-evaluations' })
          }
        })
    }
  },
  beforeDestroy() {
    this.$store.dispatch('timer/onStart', true)
    this.$store.dispatch('timer/setTempsEcoule', false)
    if (this.eval !== null && this.eval.evaluation.id !== null && this.evaluation.etat === 'en_cours') {
      this.evaluation.etat = 'abandonnee'
      this.$store.dispatch('evaluation/updateEvaluation', { id: this.eval.evaluation.id, etat: this.evaluation.etat })
    }
  },
  methods: {
    getDocumentationCompetence(id) {
      const competence = this.competences?.filter(elem => elem.id === id)
      if (competence.length > 0) {
        return competence[0].documentation
      }
      return null
    },
    setDocumentation() {
      if (this.evaluation.competenceId !== null) {
        this.documentation = this.getDocumentationCompetence(this.evaluation.competenceId)
      } else {
        this.documentation = null
      }
    },
    getCorrection() {
      const that = this
      this.evaluation.etat = 'terminee'
      this.$store.dispatch('evaluation/updateEvaluation', { id: this.eval.evaluation.id, etat: this.evaluation.etat })

      this.$store.dispatch('evaluation/getEvalCorrection', { id: this.eval.evaluation.id }).then(
        data => {
          that.correction = data
          that.resultatEvaluation = {
            series: [((parseFloat(data.grade) / 20) * 100)],
            totalEvaluation: parseFloat(data.grade),
            totalReponsesErreurEvaluation: data.wrongResponses,
            totalReponsesCorrectEvaluation: data.correctResponses,
            totalReponsesCorrectAttenduEvaluation: data.nbResponses,
            nbQuestions: data.nbQuestion,
          }
          that.$store.dispatch('timer/onStart', true)
          that.$store.dispatch('timer/setTempsEcoule', false)
        },
      )
    },
    saveResponseAction(event, reponse) {
      const newReponse = {
        questionId: reponse.questionId,
        reponseId: reponse.reponse.id,
        questionReponseId: reponse.id,
        evaluationId: this.eval.evaluation.id,
        checked: event,
      }
      this.$store.dispatch('evaluation/postEvalReponse', newReponse)
    },
    stopEvaluation() {
      this.$swal({
        title: 'Temps écoulé!',
        text: 'Vous avez atteint le temps imparti pour cette évaluation',
        icon: 'info',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      }).then(() => {
        this.getCorrection()
      })
    },
    confirmForm() {
      const that = this
      if (this.evaluation.fonctionId !== null && this.evaluation.competenceId !== null) {
        this.$refs.infoEvaluation.validate().then(success => {
          if (success) {
            this.evaluation.userId = this.getCurrentUser.id
            this.evaluation.date = new Date()
            this.evaluation.candidat = `${this.evaluation.prenom} ${this.evaluation.nom}`
            this.evaluation.societe = this.getCurrentUser.societe

            this.$store.dispatch('evaluation/createEval', this.evaluation).then(
              data => {
                this.eval = data
                this.$store.dispatch('user/getCredit', that.evaluation.userId).then(cred => {
                  const userData = that.getCurrentUser
                  userData.solde = cred
                  localStorage.setItem('userData', JSON.stringify(userData))
                })
                this.evalIsReady = true
                this.$store.dispatch('timer/onStart')
                this.$store.dispatch('timer/setTempsEcoule', false)
              },
            )
          }
        })
      }
    },
    generatePDF(id) {
      if (!this.waitDownload(id)) {
        const that = this
        this.isDownloading.push(id)
        this.$store.dispatch('evaluation/generatePDF', { id }).then(pdfData => {
          const blob = new Blob([pdfData], { type: 'application/pdf' })
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.setAttribute('download', `${id}.pdf`)
          document.body.appendChild(link)
          link.click()
          this.isDownloading = this.isDownloading.filter(e => e !== id)
        })
          .catch(() => {
            that.$swal({
              width: 500,
              title: 'Recuperation du fichier impossible',
              text: "Une erreur technique s'est produite. Veuillez contacter le support SQE Services",
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
            this.isDownloading = this.isDownloading.filter(e => e !== id)
          })
      }
    },
    waitDownload(id) {
      return this.isDownloading.includes(id)
    },
    envoyerDemande(id) {
      const that = this
      if (this.envoyerDemande === false) {
        this.envoiDemande = true
        this.$store.dispatch('evaluation/demandeDetail', { id })
          .then(() => {
            that.$swal({
              title: 'Demande de rapport détaillé',
              text: 'SQE Services a été prévenu de votre demande, merci.',
              icon: 'success',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
            this.envoiDemande = false
          })
          .catch(() => {
            that.$swal({
              width: 500,
              title: 'Demande de rapport détaillé',
              text: "Une erreur technique s'est produite. Veuillez contacter le support SQE Services",
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
            this.envoiDemande = false
          })
      }
    },
  },
}
</script>
