<template>
  <b-row>
    <b-col md="12">
      <h4>
        <feather-icon icon="AwardIcon" />
        Résultat et score de l'évaluation
      </h4>
      <b-row align-v="center">
        <b-col md="6">
          <vue-apex-charts
            type="radialBar"
            height="245"
            class="my-2"
            :options="scoreChart"
            :series="series"
          />
          <b-row class="text-center mx-0">
            <!--
            <b-col
              cols="4"
              class="border-top border-right d-flex align-items-between flex-column py-1"
            >
              <b-card-text class="text-muted mb-0">
                Réponses correctes
              </b-card-text>
              <h3 class="font-weight-bolder mb-0">
                {{ reponsesok }} / {{ reponsesattendu }}
              </h3>
            </b-col>
            -->
            <b-col
              cols="6"
              class="border-top border-right d-flex align-items-between flex-column py-1"
            >
              <b-card-text class="text-muted mb-0">
                Nombre d'erreurs
              </b-card-text>
              <h3 class="font-weight-bolder mb-0">
                {{ reponsesko }}
              </h3>
            </b-col>

            <b-col
              cols="6"
              class="border-top d-flex align-items-between flex-column py-1"
            >
              <b-card-text class="text-muted mb-0">
                Nombre de questions
              </b-card-text>
              <h3 class="font-weight-bolder mb-0">
                {{ questions }}
              </h3>
            </b-col>
          </b-row>
        </b-col>

        <b-col
          md="6"
          class="text-center"
        >
          <h5 class="text-center">
            Suggestion de qualification
          </h5>
          <h2 :class="suggestionEval('class')">
            {{ suggestionEval('texte') }}
          </h2>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow, BCol, BCardText,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'

export default {
  components: {
    BRow,
    BCol,
    BCardText,
    VueApexCharts,
  },
  props:{
    series: {
      type: Array,
      default: null,
    },
    note: {
      type: Number,
      default: 0,
    },
    reponsesok: {
      type: Number,
      default: 0,
    },
    reponsesko: {
      type: Number,
      default: 0,
    },
    reponsesattendu: {
      type: Number,
      default: 0,
    },
    questions: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      scoreChart: {
        chart: {
          height: 245,
          type: 'radialBar',
          sparkline: {
            enabled: true,
          },
          dropShadow: {
            enabled: true,
            blur: 3,
            left: 1,
            top: 1,
            opacity: 0.1,
          },
        },
        colors: ['#51e5a8'],
        plotOptions: {
          radialBar: {
            offsetY: -10,
            startAngle: -150,
            endAngle: 150,
            hollow: {
              size: '77%',
            },
            track: {
              background: 'ebe9f1',
              strokeWidth: '50%',
            },
            dataLabels: {
              name: {
                show: false,
              },
              value: {
                fontSize: '2.86rem',
                fontWeight: '600',
                formatter(val) {
                  const retour = ((val / 100) * 20).toFixed(1)
                  return `${retour} /20`
                },
              },
            },
          },
        },
        fill: {
          type: 'gradient',
          gradient: {
            shade: 'dark',
            type: 'horizontal',
            shadeIntensity: 0.5,
            gradientToColors: ['#28c76f'],
            inverseColors: true,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 100],
          },
        },
        stroke: {
          lineCap: 'round',
        },
        grid: {
          padding: {
            bottom: 30,
          },
        },
      },
    }
  },
  methods: {
    suggestionEval(type) {
      const retour = {
        class: '',
        texte: '',
      }
      if (this.note < 10) {
        retour.class = 'text-danger'
        retour.texte = 'Revoir la qualification (< 10 / 20)'
      } else if (this.note >= 13) {
        retour.class = 'text-success'
        retour.texte = 'Maintien de la qualification (≥ 13 / 20)'
      } else {
        retour.class = 'text-warning'
        retour.texte = 'Vigilance particulière (≥ 10 / 20)'
      }
      return retour[type]
    },
  },
}
</script>
